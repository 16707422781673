import { Injectable } from "@angular/core";
import CustomStore from "devextreme/data/custom_store";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import notify from "devextreme/ui/notify";
import {
  jsToSQLFilter,
  replaceInFilter,
  getHttpHeaders,
  userHeaderInfo,
} from "app/services/commons";
import { _RefHistoEventDataSource } from "app/services/reference.service";
import { AppConfig } from "app/app.config";
import { Subject } from "rxjs";

export class CriteresRecherche {
  Ids: string;
  Site_id: number;
  LuceneSearchScores: number[];
  NoeudTaxo: number;
  Titre: string;
  MotsClefs: string;
  LuceneSearch: boolean;
  Pattern: string;

  DatePublication: any;
  DateMiseEnVigueur: any;
  DateModification: any;
  DateAbrogation: any;
  DateTransfert: any;
  DateAnnulation: any;

  DatePublicationCommit: boolean = false;
  DateMiseEnVigueurCommit: boolean = false;
  DateModificationCommit: boolean = false;
  DateAbrogationCommit: boolean = false;
  DateTransfertCommit: boolean = false;
  DateAnnulationCommit: boolean = false;

  DatePublicationOp: string;
  DateMiseEnVigueurOp: string;
  DateModificationOp: string;
  DateAbrogationOp: string;
  DateTransfertOp: string;
  DateAnnulationOp: string;

  DatePublication2: any;
  DateMiseEnVigueur2: any;
  DateModification2: any;
  DateAbrogation2: any;
  DateTransfert2: any;
  DateAnnulation2: any;

  DatePublication2Op: string;
  DateMiseEnVigueur2Op: string;
  DateModification2Op: string;
  DateAbrogation2Op: string;
  DateTransfert2Op: string;
  DateAnnulation2Op: string;

  DescriptionHistorique : boolean = true;
  DernierHistorique: boolean = false;

  CodeCommit: boolean = false;
  CodeName: number;

  ReglementationEuropeene: boolean = false;
  ReglementationEuropeeneConsolidee: boolean = false;
  ControleReglementaire: boolean = false;
  ProfilType: boolean = false;
  SansHistorique: boolean = false;
  ClientCall: boolean = true;

  usagesSubject = new Subject<any>()

  reset() {
    this.Titre = "";
    this.Pattern = "";
    this.MotsClefs = "";
    this.DatePublicationCommit = false;
    this.DateMiseEnVigueurCommit = false;
    this.DateModificationCommit = false;
    this.DateAbrogationCommit = false;
    this.DateTransfertCommit = false;
    this.DateAnnulationCommit = null;
    this.CodeCommit = false;
    this.CodeName = undefined;
    this.ReglementationEuropeene = false;
    this.ReglementationEuropeeneConsolidee = false;
    this.ControleReglementaire = false;
    this.DatePublicationOp = ">"
    this.DatePublication = undefined;
    this.DatePublication2Op = "Ø";
    this.DatePublication2 = undefined;
    this.DateMiseEnVigueurOp = ">"
    this.DateMiseEnVigueur = undefined;
    this.DateMiseEnVigueur2Op = "Ø";
    this.DateMiseEnVigueur2 = undefined;
    this.LuceneSearch = false;
  }
}

@Injectable({ providedIn: "root" })
export class ReferenceUsageService {

  public currentSite: any = [0];
  public currentMode: any = [0];
  public currentQuestionnaire: any = [0,0];

  dataSource: any;

  dataSourceNonAbrogesProfil: any;
  dataSourceApplicables: any;
  dataSourceFuturs: any;
  dataSourceModifies: any;
  dataSourceAbroges: any;
  dataSourceNouveaux: any;

  currentUsageSubject = new Subject<any>();

  profilApplicableTaxoDataSource: any;
  profilNouveauTaxoDataSource: any;
  profilFuturTaxoDataSource: any;
  profilModifieTaxoDataSource: any;
  profilAbrogeTaxoDataSource: any;
  lastQueryResultSize: number;


  autoCompletionDataSource: any;

  usagesSubject = new Subject<any>()

  getProfilMotif(usageId: any, isFuture: boolean, profilId: any) {
    return this.http
      .get(
        AppConfig.settings.api +
        "/api/profil/referenceusage/motif/" +
        usageId + "/" + profilId +
        "?isFuture=" + isFuture,
        { headers: getHttpHeaders(), responseType: 'text' },
      )
      .toPromise();
  }

  public searchSitesForReferenceUsageInProfiles(referenceusage_id: number, isFuture: boolean, withMotif: boolean) {
    return this.http
      .get<any>(
        AppConfig.settings.api +
        "/api/profil/referenceusage/" +
        referenceusage_id + 
        "?isFuture=" + isFuture + 
        "&withMotif=" + withMotif,
        { headers: getHttpHeaders() }
      )
      .toPromise();
  }


  public chercheUsagesAvecMotClef(
    motclef_id: number
  ) {
    return this.http
      .get<any>(
        AppConfig.settings.api +
        "/api/referenceusage/motclef/" +
        motclef_id,
        { headers: getHttpHeaders() }
      )
      .toPromise();
  }

  public dissocierMotClefdeUsage(
    motclef_id: number,
    usage_id:number
  ) {
    return this.http
      .delete<any>(
        AppConfig.settings.api +
        "/api/referenceusage/motclef/delete/" +
        motclef_id + "/" + usage_id,
        { headers: getHttpHeaders() }
      )
      .toPromise();
  }

  public searchArchivedSitesForReferenceUsageInProfiles(
    referenceusage_id: number
  ) {
    return this.http
      .get<any>(
        AppConfig.settings.api +
        "/api/profil/archived/referenceusage/" +
        referenceusage_id,
        { headers: getHttpHeaders() }
      )
      .toPromise();
  }

  public searchTaxoSitesForReferenceUsageInProfiles(referenceusage_id: number, isFuture: boolean) {
    return this.http
      .get<any>(
        AppConfig.settings.api +
        "/api/profil/taxoreferenceusage/" +
        referenceusage_id + 
        "?isFuture=" + isFuture,
        { headers: getHttpHeaders() }
      )
      .toPromise();
  }

  public getAllReferencesUsagesSite(site_id: number) {
    return this.http
      .get<any>(
        AppConfig.settings.api + "/api/referenceusage/bysite/" + site_id,
        { headers: getHttpHeaders() }
      )
      .toPromise();
  }

  espacerRangs(filter: any) {
    return this.http
      .get<any>(
        AppConfig.settings.api +
        "/api/referenceusage/espacerangs/?filter=" +
        encodeURIComponent(jsToSQLFilter(filter)),
        { headers: getHttpHeaders() }
      )
      .toPromise();
  }

  deplacer(usages: string, targetnode: any) {
    return this.http
      .get<any>(
        AppConfig.settings.api +
        "/api/referenceusage/deplace/" +
        targetnode.id +
        "/" +
        usages,
        { headers: getHttpHeaders() }
      )
      .toPromise();
  }

  compter(node: any) {
    return this.http
      .get<any>(
        AppConfig.settings.api + "/api/referenceusage/compter/" + node,
        { headers: getHttpHeaders() }
      )
      .toPromise();
  }

  marquerCommeLu(usage: any, site: any) {
    return this.http
      .get<any>(
        AppConfig.settings.api +
        "/api/referenceusage/marquercommelu/" +
        usage +
        "/" +
        site,
        { headers: getHttpHeaders() }
      )
      .toPromise();
  }

  ajouterReferenceUsageDansProfils(refusage_id, profillist, motif, isFuture) {
    return this.http
      .post<any>(
        AppConfig.settings.api +
        "/api/referenceusage/ajouter/" +
        refusage_id +
        "?profils=" +
        profillist.join(",") + 
        "&isFuture=" + isFuture,
        motif,
        { headers: getHttpHeaders() }
      )
      .toPromise();
  }


  
  ajouterMotsClefsAuxUsage(usageKeys, motsclefsKeys) {
    return this.http
    .get<any>(
      AppConfig.settings.api +
      "/api/referenceusage/ajoutermotsclefs/" +
      usageKeys +"?motsclefs=" + motsclefsKeys.join(","),
      { headers: getHttpHeaders() }
    )
    .toPromise();
  }


  ajouterReferenceUsageMultiplesDansProfils(usagelist, profillist, motif) {
    return this.http
      .post<any>(
        AppConfig.settings.api +
        "/api/referenceusage/ajoutermultiples/" +
        usagelist.join(",") +
        "?profils=" +
        profillist.join(","),
        motif,
        { headers: getHttpHeaders() }
      )
      .toPromise();
  }


  supprimerReferenceUsageDansProfils(refusage_id, profil_liste, motif, isFuture) {
    return this.http
      .post<any>(
        AppConfig.settings.api +
        "/api/referenceusage/retirer/" +
        refusage_id +
        "?profils=" +
        profil_liste.join(",")
        +"&isFuture=" + isFuture,
        motif,
        { headers: getHttpHeaders() }
      )
      .toPromise();
  }


  fusionnerSyntagmesCommeMotsclefs(values: any) {
    return this.http.post<any>(AppConfig.settings.api + "/api/referenceusage/fusionmotsclefs/", values, { headers: getHttpHeaders() }).toPromise().then(response => {
      return response;
    }).catch(function (err) {
      notify("fusionnerSyntagmesCommeMotsclefs ERREUR: " + err);
    });
  }

  constructor(private http: HttpClient) {

    let SERVICE_URL = AppConfig.settings.api + "/api/referenceusage/";
    var currentSite = this.currentSite;
    var currentMode = this.currentMode;
    var currentQuestionnaire = this.currentQuestionnaire;

    this.dataSource = new CustomStore({
      key: "id",

      load: function (loadOptions: any): any {
       

        var futurEnabled = false;
        var filter = "?filter=id=0";

        if (AppConfig.settings.trace)
          console.log("ReferenceUsageService ", loadOptions);


        if (loadOptions.filter) {

          if (loadOptions.filter[0] == "site_id") {
            const site_id = loadOptions.filter[2];

            return http
              .get<any>(SERVICE_URL + "bysite/" + site_id, {
                headers: getHttpHeaders(),
              })
              .toPromise();
          }

          if (loadOptions.filter[0] != "searchbundle") {
            // Test pour l'activation de l'inclusion des ReferenceUsageFutur
            var item = loadOptions.filter[loadOptions.filter.length - 1];
            if (item == "futur") {
              if (AppConfig.settings.trace) console.log("FUTUR FOUND!");
              futurEnabled = true;
              loadOptions.filter.pop();
            } else futurEnabled = false;

            replaceInFilter(
              loadOptions.filter,
              "nomReference",
              "n.reference.nom"
            );

            replaceInFilter(
              loadOptions.filter,
              "nomReferencePlusCode",
              "n.reference.nom"
            );

            replaceInFilter(
              loadOptions.filter,
              "reference_id",
              "n.reference.id"
            )

            replaceInFilter(
              loadOptions.filter,
              "typeveille_id",
              "n.typedeveille.id"
            )

            replaceInFilter(
              loadOptions.filter,
              "thematique_id",
              "n.thematique.id"
            )

            replaceInFilter(
              loadOptions.filter,
              "section_id",
              "n.section.id"
            )

            replaceInFilter(
              loadOptions.filter,
              "domaine_id",
              "n.domaine.id"
            )

            replaceInFilter(
              loadOptions.filter,
              "sousdomaine_id",
              "n.sousdomaine.id"
            )

            var F = jsToSQLFilter(loadOptions.filter);
            if (AppConfig.settings.trace) console.log("FILTER=", F);

            filter =
              "?filter=" +
              encodeURIComponent(jsToSQLFilter(loadOptions.filter)) +
              "&futur=" +
              (futurEnabled ? "1" : "0");
          } else {
            filter = "?filter=" + encodeURIComponent(loadOptions.filter[2]);
          }
        }
        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        } else filter = filter + "&skip=0";

        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference")
              field = "n.reference.nom";
            if (loadOptions.sort[i].selector == "nomReferencePlusCode")
              field = "RU.reference.nom";
            else field = loadOptions.sort[i].selector;

            usort =
              usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length) usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }
       
        if(currentQuestionnaire[0]>0)
         {
          filter = filter + "&questionnaire_id=" + encodeURIComponent(currentQuestionnaire[0]);
         }

         if(AppConfig.settings.trace)
        console.log(
          " ReferenceUsageService load: calling url: " + SERVICE_URL + filter
        );

        return http
          .get<any>(SERVICE_URL + filter, { headers: getHttpHeaders() })
          .toPromise()
          .then((response) => {

            // rounding for UX purposes
            response.data.map(x => x['luceneSearchScore'] = Math.round(x['luceneSearchScore'] * 100) / 100)

            if (futurEnabled) {
              for (var i = 0; i < response.data.length; i++) {
                var obj = response.data[i];
                if (obj.futur) obj.id = obj.futurId;
              }
            }
            if (AppConfig.settings.trace)
              console.log(
                "---------------> ReferenceUsageService SERVER RESPONSE: ",
                response
              );
            return response;
          });
      },

      // totalCount: function(loadOptions:any) { return 0;},

      byKey: function (key) {
        // console.log("byKey: " + SERVICE_URL + encodeURIComponent(key));
        return http
          .get(SERVICE_URL + encodeURIComponent(key), {
            headers: getHttpHeaders(),
          })
          .toPromise();
      },
      insert: function (values:any) {
        if (AppConfig.settings.trace) console.log("CREATION: ", values);

        // Need to create user (password, email)

        if (AppConfig.settings.trace)
          console.log("insert: post " + SERVICE_URL);
        return http
          .post<any>(SERVICE_URL, values, { headers: getHttpHeaders() })
          .toPromise()
          .then((response) => {
            values.id = response.key;
            return {
              values: response.values,
              key: response.key,
            };
          })
          .catch(function (err) {
            //new
            return err;
          });
      },
      update: function (key, values) {
        if (AppConfig.settings.trace)
          console.log("update put: " + SERVICE_URL + encodeURIComponent(key));
        if (AppConfig.settings.trace) console.log("update values:", values);
        return http
          .put<any>(SERVICE_URL + encodeURIComponent(key), values, {
            headers: getHttpHeaders(),
          })
          .toPromise()
          .then((response) => {
            notify("Mise à jour effectuée");
            return {
              result: response.result,
              key: response.key,
            };
          });
      },
      remove: function (key) {
        // Need to refresh cache after that ?
        if (AppConfig.settings.trace)
          console.log(
            "remove delete: " + SERVICE_URL + encodeURIComponent(key)
          );
        return http
          .delete<any>(SERVICE_URL + encodeURIComponent(key), {
            headers: getHttpHeaders(),
          })
          .toPromise();
      },
    });

    this.dataSourceApplicables = new CustomStore({
      key: "id",

      load: function (loadOptions: any): any {
        var futurEnabled = false;
        var filter = "?filter=0";

        if (AppConfig.settings.trace) {
          console.log("ReferenceUsageService:Applicables ", loadOptions);
        console.log("loadOptions", loadOptions);
        }
        if (loadOptions.filter) {
          if (loadOptions.filter[0] != "searchbundle") {
            // Test pour l'activation de l'inclusion des ReferenceUsageFutur
            var item = loadOptions.filter[loadOptions.filter.length - 1];
            if (item == "futur") {
              if (AppConfig.settings.trace) console.log("FUTUR FOUND!");
              futurEnabled = true;
              loadOptions.filter.pop();
            } else futurEnabled = false;

            replaceInFilter(
              loadOptions.filter,
              "nomReference",
              "n.reference.nom"
            );
            replaceInFilter(
              loadOptions.filter,
              "nomReferencePlusCode",
              "n.reference.nom"
            );
            var F = jsToSQLFilter(loadOptions.filter);
            if (AppConfig.settings.trace) console.log("FILTER=", F);

            filter =
              "?filter=" +
              encodeURIComponent(jsToSQLFilter(loadOptions.filter)) +
              "&futur=" +
              (futurEnabled ? "1" : "0");
          } else {
            filter = "?filter=" + encodeURIComponent(loadOptions.filter[2]);
          }
        }
        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        } else filter = filter + "&skip=0";

        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference")
              field = "RU.reference.nom";
            if (loadOptions.sort[i].selector == "nomReferencePlusCode")
              field = "RU.reference.nom";
            else field = loadOptions.sort[i].selector;

            usort =
              usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length) usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }


      //  if(currentSite[0]==0) return { data: [], totalCount:0 }

        if (AppConfig.settings.trace)
          console.log(
            " ReferenceUsageService load: calling url: " +
            SERVICE_URL +
            "applicables/" +
            currentSite[0] + //userHeaderInfo[2] +
            filter
          );

        // return http.get<any>(SERVICE_URL +"applicables/" + "1" + filter, { headers:  getHttpHeaders() }).toPromise()
        return http
          .get<any>(SERVICE_URL + "applicables/" + currentSite[0] /*userHeaderInfo[2]*/ + filter, {
            headers: getHttpHeaders(),
          })
          .toPromise()
          .then(
            (response) => {

              // overriding totalCount for display purposes
              // wrong solution
              // response.totalCount = response.data.length
             // console.log("RESPONSE=", response)

              if (loadOptions.filter) {
                let jsonLoadOptions = JSON.parse(loadOptions.filter[2]);

                if (jsonLoadOptions.Ids) {
                  let refUsages_ids: number[] = jsonLoadOptions.Ids.replace(
                    "(",
                    ""
                  )
                    .replace(")", "")
                    .split(",")
                    .map((x) => Number(x));
                  let luceneSearchScores = jsonLoadOptions.LuceneSearchScores;

                 // console.log('luceneSearchScores');
                 // console.log(luceneSearchScores);

                  // We add scores from lucene
                  for (let i = 0; i < refUsages_ids.length; i++) {
                    let refusage = response.data.find(
                      (x) => x.id == refUsages_ids[i]
                    );

                    refusage.luceneSearchScore = luceneSearchScores[i]
                    // refusage.luceneSearchScore = Math.round(luceneSearchScores[i] * 100) / 100;
                  }
                }
              }

              if (futurEnabled) {
                for (var i = 0; i < response.data.length; i++) {
                  var obj = response.data[i];
                  if (obj.futur) obj.id = obj.futurId;
                }
              }

              if (AppConfig.settings.trace)
                console.log(
                  "---------------> applicables ReferenceUsageService Applicables SERVER RESPONSE: ",
                  response
                );
              return response;
            },
            (reject) => {
              console.log(reject);
            }
          );
      },

      update: function (key, values) {
        values["site"] = currentSite[0];
        if (AppConfig.settings.trace)
          console.log("update put: " + SERVICE_URL + encodeURIComponent(key));
        if (AppConfig.settings.trace) console.log("update values:", values);
        return http
          .put<any>(SERVICE_URL + encodeURIComponent(key), values, {
            headers: getHttpHeaders(),
          })
          .toPromise()
          .then((response) => {
            return {
              result: response.result,
              key: response.key,
            };
          });
      },
    });

    this.dataSourceFuturs = new CustomStore({
      key: "id",

      load: function (loadOptions: any): any {
        var futurEnabled = false;
        var filter = "?filter=0";

        if (loadOptions.filter) {
          if (loadOptions.filter[0] != "searchbundle") {
            // Test pour l'activation de l'inclusion des ReferenceUsageFutur
            var item = loadOptions.filter[loadOptions.filter.length - 1];
            if (item == "futur") {
              if (AppConfig.settings.trace) console.log("FUTUR FOUND!");
              futurEnabled = true;
              loadOptions.filter.pop();
            } else futurEnabled = false;

            replaceInFilter(
              loadOptions.filter,
              "nomReference",
              "n.reference.nom"
            );
            replaceInFilter(
              loadOptions.filter,
              "nomReferencePlusCode",
              "n.reference.nom"
            );
            var F = jsToSQLFilter(loadOptions.filter);
            if (AppConfig.settings.trace) console.log("FILTER=", F);

            filter =
              "?filter=" +
              encodeURIComponent(jsToSQLFilter(loadOptions.filter)) +
              "&futur=" +
              (futurEnabled ? "1" : "0");
          } else {
            filter = "?filter=" + encodeURIComponent(loadOptions.filter[2]);
          }
        }
        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        } else filter = filter + "&skip=0";

        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference")
              field = "RU.reference.nom";
            if (loadOptions.sort[i].selector == "nomReferencePlusCode")
              field = "RU.reference.nom";

            else field = loadOptions.sort[i].selector;

            usort =
              usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length) usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }

        if (AppConfig.settings.trace)
          console.log(
            " ReferenceUsageService load: calling url: " +
            SERVICE_URL +
            "futurs/" +
            userHeaderInfo[2] +
            filter
          );

        return http
          .get<any>(SERVICE_URL + "futurs/" + userHeaderInfo[2] + filter, {
            headers: getHttpHeaders(),
          })
          .toPromise()
          .then((response) => {
            if (futurEnabled) {
              for (var i = 0; i < response.data.length; i++) {
                var obj = response.data[i];
                if (obj.futur) obj.id = obj.futurId;
              }
            }
            if (AppConfig.settings.trace)
              console.log(
                "---------------> ReferenceUsageService futurs  SERVER RESPONSE: ",
                response
              );
            return response;
          });
      },
      update: function (key, values) {
        values["site"] = currentSite[0];
        if (AppConfig.settings.trace)
          console.log("update put: " + SERVICE_URL + encodeURIComponent(key));
        if (AppConfig.settings.trace) console.log("update values:", values);
        return http
          .put<any>(SERVICE_URL + encodeURIComponent(key), values, {
            headers: getHttpHeaders(),
          })
          .toPromise()
          .then((response) => {
            return {
              result: response.result,
              key: response.key,
            };
          });
      },
    });

    this.dataSourceModifies = new CustomStore({
      key: "id",

      load: function (loadOptions: any): any {
        var futurEnabled = false;
        var filter = "?filter=0";

        if (AppConfig.settings.trace)
          console.log("ReferenceUsageService:Modifies ", loadOptions);

        if (loadOptions.filter) {
          if (loadOptions.filter[0] != "searchbundle") {
            // Test pour l'activation de l'inclusion des ReferenceUsageFutur
            var item = loadOptions.filter[loadOptions.filter.length - 1];
            if (item == "futur") {
              if (AppConfig.settings.trace) console.log("FUTUR FOUND!");
              futurEnabled = true;
              loadOptions.filter.pop();
            } else futurEnabled = false;

            replaceInFilter(
              loadOptions.filter,
              "nomReference",
              "n.reference.nom"
            );

            replaceInFilter(
              loadOptions.filter,
              "nomReferencePlusCode",
              "n.reference.nom"
            );
            var F = jsToSQLFilter(loadOptions.filter);
            if (AppConfig.settings.trace) console.log("FILTER=", F);

            filter =
              "?filter=" +
              encodeURIComponent(jsToSQLFilter(loadOptions.filter)) +
              "&futur=" +
              (futurEnabled ? "1" : "0");
          } else {
            filter = "?filter=" + encodeURIComponent(loadOptions.filter[2]);
          }
        }
        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        } else filter = filter + "&skip=0";

        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference")
              field = "RU.reference.nom";
            if (loadOptions.sort[i].selector == "nomReferencePlusCode")
              field = "RU.reference.nom";
            else field = loadOptions.sort[i].selector;

            usort =
              usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length) usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }

        if (AppConfig.settings.trace)
          console.log(
            " ReferenceUsageService load: calling url: " +
            SERVICE_URL +
            "modifies/" +
            userHeaderInfo[2] +
            filter
          );

        return http
          .get<any>(SERVICE_URL + "modifies/" + userHeaderInfo[2] + filter, {
            headers: getHttpHeaders(),
          })
          .toPromise()
          .then((response) => {
            if (futurEnabled) {
              for (var i = 0; i < response.data.length; i++) {
                var obj = response.data[i];
                if (obj.futur) obj.id = obj.futurId;
              }
            }
            if (AppConfig.settings.trace)
              console.log(
                "---------------> ReferenceUsageService modifiés SERVER RESPONSE: ",
                response
              );
            return response;
          });
      },
      update: function (key, values) {
        values["site"] = currentSite[0];
        if (AppConfig.settings.trace)
          console.log("update put: " + SERVICE_URL + encodeURIComponent(key));
        if (AppConfig.settings.trace) console.log("update values:", values);
        return http
          .put<any>(SERVICE_URL + encodeURIComponent(key), values, {
            headers: getHttpHeaders(),
          })
          .toPromise()
          .then((response) => {
            return {
              result: response.result,
              key: response.key,
            };
          });
      },
    });

    this.dataSourceAbroges = new CustomStore({
      key: "id",

      load: function (loadOptions: any): any {
        var futurEnabled = false;
        var filter = "?filter=0";

        if (AppConfig.settings.trace)
          console.log("ReferenceUsageService:Abroges ", loadOptions);

        if (loadOptions.filter) {
          if (loadOptions.filter[0] != "searchbundle") {
            // Test pour l'activation de l'inclusion des ReferenceUsageFutur
            var item = loadOptions.filter[loadOptions.filter.length - 1];
            if (item == "futur") {
              if (AppConfig.settings.trace) console.log("FUTUR FOUND!");
              futurEnabled = true;
              loadOptions.filter.pop();
            } else futurEnabled = false;

            replaceInFilter(
              loadOptions.filter,
              "nomReference",
              "n.reference.nom"
            );

            replaceInFilter(
              loadOptions.filter,
              "nomReferencePlusCode",
              "n.reference.nom"
            );
            var F = jsToSQLFilter(loadOptions.filter);
            if (AppConfig.settings.trace) console.log("FILTER=", F);

            filter =
              "?filter=" +
              encodeURIComponent(jsToSQLFilter(loadOptions.filter)) +
              "&futur=" +
              (futurEnabled ? "1" : "0");
          } else {
            filter = "?filter=" + encodeURIComponent(loadOptions.filter[2]);
          }
        }
        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        } else filter = filter + "&skip=0";

        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference")
              field = "RU.reference.nom";
            if (loadOptions.sort[i].selector == "nomReferencePlusCode")
              field = "RU.reference.nom";
            else field = loadOptions.sort[i].selector;

            usort =
              usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length) usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }

        if (AppConfig.settings.trace)
          console.log(
            " ReferenceUsageService load: calling url: " +
            SERVICE_URL +
            "abroges/" +
            userHeaderInfo[2] +
            filter
          );

        return http
          .get<any>(SERVICE_URL + "abroges/" + userHeaderInfo[2] + filter, {
            headers: getHttpHeaders(),
          })
          .toPromise()
          .then((response) => {
            if (futurEnabled) {
              for (var i = 0; i < response.data.length; i++) {
                var obj = response.data[i];
                if (obj.futur) obj.id = obj.futurId;
              }
            }
            if (AppConfig.settings.trace)
              console.log(
                "---------------> ReferenceUsageService abrogés SERVER RESPONSE: ",
                response
              );
            return response;
          });
      },
      update: function (key, values) {
        values["site"] = currentSite[0];
        if (AppConfig.settings.trace)
          console.log("update put: " + SERVICE_URL + encodeURIComponent(key));
        if (AppConfig.settings.trace) console.log("update values:", values);
        return http
          .put<any>(SERVICE_URL + encodeURIComponent(key), values, {
            headers: getHttpHeaders(),
          })
          .toPromise()
          .then((response) => {
            return {
              result: response.result,
              key: response.key,
            };
          });
      },
    });

    this.dataSourceNouveaux = new CustomStore({
      key: "id",

      load: function (loadOptions: any): any {
        var futurEnabled = false;
        var filter = "?filter=0";

        if (AppConfig.settings.trace)
          console.log("ReferenceUsageService:Nouveaux ", loadOptions);

        if (loadOptions.filter) {
          if (loadOptions.filter[0] != "searchbundle") {
            // Test pour l'activation de l'inclusion des ReferenceUsageFutur
            var item = loadOptions.filter[loadOptions.filter.length - 1];
            if (item == "futur") {
              if (AppConfig.settings.trace) console.log("FUTUR FOUND!");
              futurEnabled = true;
              loadOptions.filter.pop();
            } else futurEnabled = false;

            replaceInFilter(
              loadOptions.filter,
              "nomReference",
              "n.reference.nom"
            );

            replaceInFilter(
              loadOptions.filter,
              "nomReferencePlusCode",
              "n.reference.nom"
            );
            var F = jsToSQLFilter(loadOptions.filter);
            if (AppConfig.settings.trace) console.log("FILTER=", F);

            filter =
              "?filter=" +
              encodeURIComponent(jsToSQLFilter(loadOptions.filter)) +
              "&futur=" +
              (futurEnabled ? "1" : "0");
          } else {
            filter = "?filter=" + encodeURIComponent(loadOptions.filter[2]);
          }
        }
        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        } else filter = filter + "&skip=0";

        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference")
              field = "RU.reference.nom";
            if (loadOptions.sort[i].selector == "nomReferencePlusCode")
              field = "RU.reference.nom";
            else field = loadOptions.sort[i].selector;

            usort =
              usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length) usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }

        if (AppConfig.settings.trace)
          console.log(
            " ReferenceUsageService load: calling url: " +
            SERVICE_URL +
            "nouveaux/" +
            userHeaderInfo[2] +
            filter
          );

        return http
          .get<any>(SERVICE_URL + "nouveaux/" + userHeaderInfo[2] + filter, {
            headers: getHttpHeaders(),
          })
          .toPromise()
          .then((response) => {
            if (futurEnabled) {
              for (var i = 0; i < response.data.length; i++) {
                var obj = response.data[i];
                if (obj.futur) obj.id = obj.futurId;
              }
            }
            if (AppConfig.settings.trace)
              console.log(
                "---------------> ReferenceUsageService nouveaux SERVER RESPONSE: ",
                response
              );
            return response;
          });
      },
      update: function (key, values) {
        values["site"] = currentSite[0];
        if (AppConfig.settings.trace)
          console.log("update put: " + SERVICE_URL + encodeURIComponent(key));
        if (AppConfig.settings.trace) console.log("update values:", values);
        return http
          .put<any>(SERVICE_URL + encodeURIComponent(key), values, {
            headers: getHttpHeaders(),
          })
          .toPromise()
          .then((response) => {
            return {
              result: response.result,
              key: response.key,
            };
          });
      },
    });

    /* **********  */

    this.profilApplicableTaxoDataSource = new CustomStore({
      key: "id",
      load: function (loadOptions: any): any {
        let params = "";
        let filter = "";
        var pp = "?";
        if (loadOptions.parentIds)
          params = "children/" + loadOptions.parentIds.join(","); // picking the last parentId of the list

        // console.log(loadOptions);

        if (this.restrictedParents) {
          params = "children/0?retain=" + this.restrictedParents.join(",");
        } else if (
          loadOptions.filter &&
          params.length == 0 &&
          loadOptions.filter[2] &&
          loadOptions.filter[2] != undefined
        ) {
          //console.log ( "SQL FILTER:"+ gservice.jsToSQLFilter(loadOptions.filter));
          filter =
            "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
          pp = "&";
        }

        if (AppConfig.settings.trace)
          console.log(
            "ReferenceUsageService load: calling url: " +
            SERVICE_URL +
            "applicables/taxo/" +
            userHeaderInfo[2] +
            filter
          );

        return http
          .get<any>(
            SERVICE_URL + "applicables/taxo/" + userHeaderInfo[2] + filter,
            { headers: getHttpHeaders() }
          )
          .toPromise()
          .then((response) => {
            this.restrictedParents = undefined;
            if (AppConfig.settings.trace)
              console.log(
                "------> ReferenceUsageService SERVER RESPONSE: ",
                response.data
              );

            return response.data;
          });
      },

      // totalCount: function(loadOptions:any) { return 0;},
      byKey: function (key) {
        if (AppConfig.settings.trace)
          console.log(
            " ProfilService byKey: " +
            SERVICE_URL +
            "taxo/" +
            encodeURIComponent(key)
          );
        return http
          .get(SERVICE_URL + "taxo/" + encodeURIComponent(key), {
            headers: getHttpHeaders(),
          })
          .toPromise();
      },
    });

    this.profilNouveauTaxoDataSource = new CustomStore({
      key: "id",
      load: function (loadOptions: any): any {
        let params = "";
        let filter = "";
        var pp = "?";
        if (loadOptions.parentIds)
          params = "children/" + loadOptions.parentIds.join(","); // picking the last parentId of the list

        // console.log(loadOptions);

        if (this.restrictedParents) {
          params = "children/0?retain=" + this.restrictedParents.join(",");
        } else if (
          loadOptions.filter &&
          params.length == 0 &&
          loadOptions.filter[2] &&
          loadOptions.filter[2] != undefined
        ) {
          //console.log ( "SQL FILTER:"+ gservice.jsToSQLFilter(loadOptions.filter));
          filter =
            "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
          pp = "&";
        }

        if (AppConfig.settings.trace)
          console.log(
            "ReferenceUsageService load: calling url: " +
            SERVICE_URL +
            "nouveaux/taxo/" +
            userHeaderInfo[2] +
            filter
          );

        return http
          .get<any>(
            SERVICE_URL + "nouveaux/taxo/" + userHeaderInfo[2] + filter,
            { headers: getHttpHeaders() }
          )
          .toPromise()
          .then((response) => {
            this.restrictedParents = undefined;
            if (AppConfig.settings.trace)
              console.log(
                "------> ReferenceUsageService SERVER RESPONSE: ",
                response.data
              );

            return response.data;
          });
      },

      // totalCount: function(loadOptions:any) { return 0;},
      byKey: function (key) {
        if (AppConfig.settings.trace)
          console.log(
            " ProfilService byKey: " +
            SERVICE_URL +
            "taxo/" +
            encodeURIComponent(key)
          );
        return http
          .get(SERVICE_URL + "taxo/" + encodeURIComponent(key), {
            headers: getHttpHeaders(),
          })
          .toPromise();
      },
    });

    this.profilFuturTaxoDataSource = new CustomStore({
      key: "id",
      load: function (loadOptions: any): any {
        let params = "";
        let filter = "";
        var pp = "?";
        if (loadOptions.parentIds)
          params = "children/" + loadOptions.parentIds.join(","); // picking the last parentId of the list

        // console.log(loadOptions);

        if (this.restrictedParents) {
          params = "children/0?retain=" + this.restrictedParents.join(",");
        } else if (
          loadOptions.filter &&
          params.length == 0 &&
          loadOptions.filter[2] &&
          loadOptions.filter[2] != undefined
        ) {
          //console.log ( "SQL FILTER:"+ gservice.jsToSQLFilter(loadOptions.filter));
          filter =
            "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
          pp = "&";
        }

        if (AppConfig.settings.trace)
          console.log(
            "ReferenceUsageService load: calling url: " +
            SERVICE_URL +
            "futurs/taxo/" +
            userHeaderInfo[2] +
            filter
          );

        return http
          .get<any>(SERVICE_URL + "futurs/taxo/" + userHeaderInfo[2] + filter, {
            headers: getHttpHeaders(),
          })
          .toPromise()
          .then((response) => {
            this.restrictedParents = undefined;
            if (AppConfig.settings.trace)
              console.log(
                "------> ReferenceUsageService SERVER RESPONSE: ",
                response.data
              );

            return response.data;
          });
      },

      // totalCount: function(loadOptions:any) { return 0;},
      byKey: function (key) {
        if (AppConfig.settings.trace)
          console.log(
            " ProfilService byKey: " +
            SERVICE_URL +
            "taxo/" +
            encodeURIComponent(key)
          );
        return http
          .get(SERVICE_URL + "taxo/" + encodeURIComponent(key), {
            headers: getHttpHeaders(),
          })
          .toPromise();
      },
    });

    this.profilModifieTaxoDataSource = new CustomStore({
      key: "id",
      load: function (loadOptions: any): any {
        let params = "";
        let filter = "";
        var pp = "?";
        if (loadOptions.parentIds)
          params = "children/" + loadOptions.parentIds.join(","); // picking the last parentId of the list

        // console.log(loadOptions);

        if (this.restrictedParents) {
          params = "children/0?retain=" + this.restrictedParents.join(",");
        } else if (
          loadOptions.filter &&
          params.length == 0 &&
          loadOptions.filter[2] &&
          loadOptions.filter[2] != undefined
        ) {
          //console.log ( "SQL FILTER:"+ gservice.jsToSQLFilter(loadOptions.filter));
          filter =
            "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
          pp = "&";
        }

        if (AppConfig.settings.trace)
          console.log(
            "ReferenceUsageService load: calling url: " +
            SERVICE_URL +
            "modifies/taxo/" +
            userHeaderInfo[2] +
            filter
          );

        return http
          .get<any>(
            SERVICE_URL + "modifies/taxo/" + userHeaderInfo[2] + filter,
            { headers: getHttpHeaders() }
          )
          .toPromise()
          .then((response) => {
            this.restrictedParents = undefined;
            if (AppConfig.settings.trace)
              console.log(
                "------> ReferenceUsageService SERVER RESPONSE: ",
                response.data
              );

            return response.data;
          });
      },

      // totalCount: function(loadOptions:any) { return 0;},
      byKey: function (key) {
        if (AppConfig.settings.trace)
          console.log(
            " ProfilService byKey: " +
            SERVICE_URL +
            "taxo/" +
            encodeURIComponent(key)
          );
        return http
          .get(SERVICE_URL + "taxo/" + encodeURIComponent(key), {
            headers: getHttpHeaders(),
          })
          .toPromise();
      },
    });

    this.profilAbrogeTaxoDataSource = new CustomStore({
      key: "id",
      load: function (loadOptions: any): any {
        let params = "";
        let filter = "";
        var pp = "?";
        if (loadOptions.parentIds)
          params = "children/" + loadOptions.parentIds.join(","); // picking the last parentId of the list

        // console.log(loadOptions);

        if (this.restrictedParents) {
          params = "children/0?retain=" + this.restrictedParents.join(",");
        } else if (
          loadOptions.filter &&
          params.length == 0 &&
          loadOptions.filter[2] &&
          loadOptions.filter[2] != undefined
        ) {
          //console.log ( "SQL FILTER:"+ gservice.jsToSQLFilter(loadOptions.filter));
          filter =
            "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
          pp = "&";
        }

        if (AppConfig.settings.trace)
          console.log(
            "ReferenceUsageService load: calling url: " +
            SERVICE_URL +
            "abroges/taxo/" +
            userHeaderInfo[2] +
            filter
          );

        return http
          .get<any>(
            SERVICE_URL + "abroges/taxo/" + userHeaderInfo[2] + filter,
            { headers: getHttpHeaders() }
          )
          .toPromise()
          .then((response) => {
            this.restrictedParents = undefined;
            if (AppConfig.settings.trace)
              console.log(
                "------> ReferenceUsageService SERVER RESPONSE: ",
                response.data
              );

            return response.data;
          });
      },

      // totalCount: function(loadOptions:any) { return 0;},
      byKey: function (key) {
        if (AppConfig.settings.trace)
          console.log(
            " ProfilService byKey: " +
            SERVICE_URL +
            "taxo/" +
            encodeURIComponent(key)
          );
        return http
          .get(SERVICE_URL + "taxo/" + encodeURIComponent(key), {
            headers: getHttpHeaders(),
          })
          .toPromise();
      },
    });








// 
    this.autoCompletionDataSource = new CustomStore({
      key: "id",

      load: function (loadOptions: any): any {

        return http
          .get<any>(AppConfig.settings.api + "/api/search/autocomplete/"+currentMode[0]+"/"+userHeaderInfo[2] + "?sw="+encodeURIComponent(loadOptions.searchValue), { headers: getHttpHeaders() })
          .toPromise().then((response) => {

            if (AppConfig.settings.trace)
              console.log(
                "---------------> /search/autocomplete/ SERVER RESPONSE: ",
                response
              );
            return response;
          });
      },

    });
  }

  getTitreAutocompletionDataSource() {
    return this.autoCompletionDataSource;
  }
  getRestDataSource() {
    return this.dataSource;
  }

  getRestDataSourceApplicables() {
    return this.dataSourceApplicables;
  }
  getRestDataSourceFuturs() {
    return this.dataSourceFuturs;
  }

  getRestDataSourceModifies() {
    return this.dataSourceModifies;
  }
  getRestDataSourceAbroges() {
    return this.dataSourceAbroges;
  }
  getRestDataSourceNouveaux() {
    return this.dataSourceNouveaux;
  }

  getProfilApplicableTaxoDataSource() {
    return this.profilApplicableTaxoDataSource;
  }

  getProfilNouveauTaxoDataSource() {
    return this.profilNouveauTaxoDataSource;
  }
  getProfilFuturTaxoDataSource() {
    return this.profilFuturTaxoDataSource;
  }
  getProfilModifieTaxoDataSource() {
    return this.profilModifieTaxoDataSource;
  }
  getProfilAbrogeTaxoDataSource() {
    return this.profilAbrogeTaxoDataSource;
  }

  getDataSourceNonAbrogesProfil() {
    return this.dataSourceNonAbrogesProfil;
  }

  getEvenementReferenceDataSource() {
    return _RefHistoEventDataSource;
  }
}
